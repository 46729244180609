import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    this.element.addEventListener('change', this.submit)
    this.element.addEventListener('keyup', this.delay)
  }

  submit = () => {
    this.element.form.submit()
  }

  delay = ()=> {
    setTimeout(this.submit, 1000)
  }
}
